// FIXME Временное решение, в дальшнейшем данные будут приходить с бэка
export const CURRENCIES: Array<{
  currency: string;
  decimals: number;
}> = [
  {
    currency: 'RUB',
    decimals: 2,
  },
  {
    currency: 'EUR',
    decimals: 2,
  },
  {
    currency: 'USD',
    decimals: 2,
  },
  {
    currency: 'CAD',
    decimals: 2,
  },
  {
    currency: 'BRL',
    decimals: 2,
  },
  {
    currency: 'AED',
    decimals: 2,
  },
  {
    currency: 'INR',
    decimals: 2,
  },
  {
    currency: 'AZN',
    decimals: 2,
  },
  {
    currency: 'GBP',
    decimals: 2,
  },
  {
    currency: 'ASN',
    decimals: 2,
  },
  {
    currency: 'CNY',
    decimals: 2,
  },
  {
    currency: 'JPY',
    decimals: 0,
  },
  {
    currency: 'KRW',
    decimals: 0,
  },
  {
    currency: 'BIF',
    decimals: 0,
  },
  {
    currency: 'CLP',
    decimals: 0,
  },
  {
    currency: 'KMF',
    decimals: 0,
  },
  {
    currency: 'DJF',
    decimals: 0,
  },
  {
    currency: 'GNF',
    decimals: 0,
  },
  {
    currency: 'ISK',
    decimals: 0,
  },
  {
    currency: 'VUV',
    decimals: 0,
  },
  {
    currency: 'PYG',
    decimals: 0,
  },
  {
    currency: 'VND',
    decimals: 0,
  },
  {
    currency: 'UGX',
    decimals: 0,
  },
  {
    currency: 'UYI',
    decimals: 0,
  },
  {
    currency: 'XAF',
    decimals: 0,
  },
  {
    currency: 'XOF',
    decimals: 0,
  },
  {
    currency: 'XPF',
    decimals: 0,
  },
  {
    currency: 'RWF',
    decimals: 0,
  },
  {
    currency: 'BHD',
    decimals: 3,
  },
  {
    currency: 'IQD',
    decimals: 3,
  },
  {
    currency: 'JOD',
    decimals: 3,
  },
  {
    currency: 'KWD',
    decimals: 3,
  },
  {
    currency: 'LYD',
    decimals: 3,
  },
  {
    currency: 'OMR',
    decimals: 3,
  },
  {
    currency: 'TND',
    decimals: 3,
  },
];

export const COUNTRIES: Array<{ code: string; shortName: string }> = [
  { code: 'AF', shortName: 'Afghanistan' },
  { code: 'AX', shortName: 'Åland Islands' },
  { code: 'AL', shortName: 'Albania' },
  { code: 'DZ', shortName: 'Algeria' },
  { code: 'AS', shortName: 'American Samoa' },
  { code: 'AD', shortName: 'Andorra' },
  { code: 'AO', shortName: 'Angola' },
  { code: 'AI', shortName: 'Anguilla' },
  { code: 'AQ', shortName: 'Antarctica' },
  { code: 'AG', shortName: 'Antigua and Barbuda' },
  { code: 'AR', shortName: 'Argentina' },
  { code: 'AM', shortName: 'Armenia' },
  { code: 'AW', shortName: 'Aruba' },
  { code: 'AU', shortName: 'Australia' },
  { code: 'AT', shortName: 'Austria' },
  { code: 'AZ', shortName: 'Azerbaijan' },
  { code: 'BS', shortName: 'Bahamas' },
  { code: 'BH', shortName: 'Bahrain' },
  { code: 'BD', shortName: 'Bangladesh' },
  { code: 'BB', shortName: 'Barbados' },
  { code: 'BY', shortName: 'Belarus' },
  { code: 'BE', shortName: 'Belgium' },
  { code: 'BZ', shortName: 'Belize' },
  { code: 'BJ', shortName: 'Benin' },
  { code: 'BM', shortName: 'Bermuda' },
  { code: 'BT', shortName: 'Bhutan' },
  { code: 'BO', shortName: 'Bolivia, Plurinational State of' },
  { code: 'BQ', shortName: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA', shortName: 'Bosnia and Herzegovina' },
  { code: 'BW', shortName: 'Botswana' },
  { code: 'BV', shortName: 'Bouvet Island' },
  { code: 'BR', shortName: 'Brazil' },
  { code: 'IO', shortName: 'British Indian Ocean Territory' },
  { code: 'BN', shortName: 'Brunei Darussalam' },
  { code: 'BG', shortName: 'Bulgaria' },
  { code: 'BF', shortName: 'Burkina Faso' },
  { code: 'BI', shortName: 'Burundi' },
  { code: 'KH', shortName: 'Cambodia' },
  { code: 'CM', shortName: 'Cameroon' },
  { code: 'CA', shortName: 'Canada' },
  { code: 'CV', shortName: 'Cabo Verde' },
  { code: 'KY', shortName: 'Cayman Islands' },
  { code: 'CF', shortName: 'Central African Republic' },
  { code: 'TD', shortName: 'Chad' },
  { code: 'CL', shortName: 'Chile' },
  { code: 'CN', shortName: 'China' },
  { code: 'CX', shortName: 'Christmas Island' },
  { code: 'CC', shortName: 'Cocos (Keeling) Islands' },
  { code: 'CO', shortName: 'Colombia' },
  { code: 'KM', shortName: 'Comoros' },
  { code: 'CG', shortName: 'Congo' },
  { code: 'CD', shortName: 'Congo, the Democratic Republic of the' },
  { code: 'CK', shortName: 'Cook Islands' },
  { code: 'CR', shortName: 'Costa Rica' },
  { code: 'CI', shortName: "Côte d'Ivoire" },
  { code: 'HR', shortName: 'Croatia' },
  { code: 'CU', shortName: 'Cuba' },
  { code: 'CW', shortName: 'Curaçao' },
  { code: 'CY', shortName: 'Cyprus' },
  { code: 'CZ', shortName: 'Czechia' },
  { code: 'DK', shortName: 'Denmark' },
  { code: 'DJ', shortName: 'Djibouti' },
  { code: 'DM', shortName: 'Dominica' },
  { code: 'DO', shortName: 'Dominican Republic' },
  { code: 'EC', shortName: 'Ecuador' },
  { code: 'EG', shortName: 'Egypt' },
  { code: 'SV', shortName: 'El Salvador' },
  { code: 'GQ', shortName: 'Equatorial Guinea' },
  { code: 'ER', shortName: 'Eritrea' },
  { code: 'EE', shortName: 'Estonia' },
  { code: 'ET', shortName: 'Ethiopia' },
  { code: 'FK', shortName: 'Falkland Islands (Malvinas)' },
  { code: 'FO', shortName: 'Faroe Islands' },
  { code: 'FJ', shortName: 'Fiji' },
  { code: 'FI', shortName: 'Finland' },
  { code: 'FR', shortName: 'France' },
  { code: 'GF', shortName: 'French Guiana' },
  { code: 'PF', shortName: 'French Polynesia' },
  { code: 'TF', shortName: 'French Southern Territories' },
  { code: 'GA', shortName: 'Gabon' },
  { code: 'GM', shortName: 'Gambia' },
  { code: 'GE', shortName: 'Georgia' },
  { code: 'DE', shortName: 'Germany' },
  { code: 'GH', shortName: 'Ghana' },
  { code: 'GI', shortName: 'Gibraltar' },
  { code: 'GR', shortName: 'Greece' },
  { code: 'GL', shortName: 'Greenland' },
  { code: 'GD', shortName: 'Grenada' },
  { code: 'GP', shortName: 'Guadeloupe' },
  { code: 'GU', shortName: 'Guam' },
  { code: 'GT', shortName: 'Guatemala' },
  { code: 'GG', shortName: 'Guernsey' },
  { code: 'GN', shortName: 'Guinea' },
  { code: 'GW', shortName: 'Guinea-Bissau' },
  { code: 'GY', shortName: 'Guyana' },
  { code: 'HT', shortName: 'Haiti' },
  { code: 'HM', shortName: 'Heard Island and McDonald Islands' },
  { code: 'VA', shortName: 'Holy See (Vatican City State)' },
  { code: 'HN', shortName: 'Honduras' },
  { code: 'HK', shortName: 'Hong Kong' },
  { code: 'HU', shortName: 'Hungary' },
  { code: 'IS', shortName: 'Iceland' },
  { code: 'IN', shortName: 'India' },
  { code: 'ID', shortName: 'Indonesia' },
  { code: 'IR', shortName: 'Iran, Islamic Republic of' },
  { code: 'IQ', shortName: 'Iraq' },
  { code: 'IE', shortName: 'Ireland' },
  { code: 'IM', shortName: 'Isle of Man' },
  { code: 'IL', shortName: 'Israel' },
  { code: 'IT', shortName: 'Italy' },
  { code: 'JM', shortName: 'Jamaica' },
  { code: 'JP', shortName: 'Japan' },
  { code: 'JE', shortName: 'Jersey' },
  { code: 'JO', shortName: 'Jordan' },
  { code: 'KZ', shortName: 'Kazakhstan' },
  { code: 'KE', shortName: 'Kenya' },
  { code: 'KI', shortName: 'Kiribati' },
  { code: 'KP', shortName: "Korea, Democratic People's Republic of" },
  { code: 'KR', shortName: 'Korea, Republic of' },
  { code: 'KW', shortName: 'Kuwait' },
  { code: 'KG', shortName: 'Kyrgyzstan' },
  { code: 'LA', shortName: "Lao People's Democratic Republic" },
  { code: 'LV', shortName: 'Latvia' },
  { code: 'LB', shortName: 'Lebanon' },
  { code: 'LS', shortName: 'Lesotho' },
  { code: 'LR', shortName: 'Liberia' },
  { code: 'LY', shortName: 'Libya' },
  { code: 'LI', shortName: 'Liechtenstein' },
  { code: 'LT', shortName: 'Lithuania' },
  { code: 'LU', shortName: 'Luxembourg' },
  { code: 'MO', shortName: 'Macao' },
  { code: 'MK', shortName: 'North Macedonia' },
  { code: 'MG', shortName: 'Madagascar' },
  { code: 'MW', shortName: 'Malawi' },
  { code: 'MY', shortName: 'Malaysia' },
  { code: 'MV', shortName: 'Maldives' },
  { code: 'ML', shortName: 'Mali' },
  { code: 'MT', shortName: 'Malta' },
  { code: 'MH', shortName: 'Marshall Islands' },
  { code: 'MQ', shortName: 'Martinique' },
  { code: 'MR', shortName: 'Mauritania' },
  { code: 'MU', shortName: 'Mauritius' },
  { code: 'YT', shortName: 'Mayotte' },
  { code: 'MX', shortName: 'Mexico' },
  { code: 'FM', shortName: 'Micronesia, Federated States of' },
  { code: 'MD', shortName: 'Moldova, Republic of' },
  { code: 'MC', shortName: 'Monaco' },
  { code: 'MN', shortName: 'Mongolia' },
  { code: 'ME', shortName: 'Montenegro' },
  { code: 'MS', shortName: 'Montserrat' },
  { code: 'MA', shortName: 'Morocco' },
  { code: 'MZ', shortName: 'Mozambique' },
  { code: 'MM', shortName: 'Myanmar' },
  { code: 'NA', shortName: 'Namibia' },
  { code: 'NR', shortName: 'Nauru' },
  { code: 'NP', shortName: 'Nepal' },
  { code: 'NL', shortName: 'Netherlands' },
  { code: 'NC', shortName: 'New Caledonia' },
  { code: 'NZ', shortName: 'New Zealand' },
  { code: 'NI', shortName: 'Nicaragua' },
  { code: 'NE', shortName: 'Niger' },
  { code: 'NG', shortName: 'Nigeria' },
  { code: 'NU', shortName: 'Niue' },
  { code: 'NF', shortName: 'Norfolk Island' },
  { code: 'MP', shortName: 'Northern Mariana Islands' },
  { code: 'NO', shortName: 'Norway' },
  { code: 'OM', shortName: 'Oman' },
  { code: 'PK', shortName: 'Pakistan' },
  { code: 'PW', shortName: 'Palau' },
  { code: 'PS', shortName: 'Palestine, State of' },
  { code: 'PA', shortName: 'Panama' },
  { code: 'PG', shortName: 'Papua New Guinea' },
  { code: 'PY', shortName: 'Paraguay' },
  { code: 'PE', shortName: 'Peru' },
  { code: 'PH', shortName: 'Philippines' },
  { code: 'PN', shortName: 'Pitcairn' },
  { code: 'PL', shortName: 'Poland' },
  { code: 'PT', shortName: 'Portugal' },
  { code: 'PR', shortName: 'Puerto Rico' },
  { code: 'QA', shortName: 'Qatar' },
  { code: 'RE', shortName: 'Réunion' },
  { code: 'RO', shortName: 'Romania' },
  { code: 'RU', shortName: 'Russian Federation' },
  { code: 'RW', shortName: 'Rwanda' },
  { code: 'BL', shortName: 'Saint Barthélemy' },
  { code: 'SH', shortName: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'KN', shortName: 'Saint Kitts and Nevis' },
  { code: 'LC', shortName: 'Saint Lucia' },
  { code: 'MF', shortName: 'Saint Martin (French part)' },
  { code: 'PM', shortName: 'Saint Pierre and Miquelon' },
  { code: 'VC', shortName: 'Saint Vincent and the Grenadines' },
  { code: 'WS', shortName: 'Samoa' },
  { code: 'SM', shortName: 'San Marino' },
  { code: 'ST', shortName: 'Sao Tome and Principe' },
  { code: 'SA', shortName: 'Saudi Arabia' },
  { code: 'SN', shortName: 'Senegal' },
  { code: 'RS', shortName: 'Serbia' },
  { code: 'SC', shortName: 'Seychelles' },
  { code: 'SL', shortName: 'Sierra Leone' },
  { code: 'SG', shortName: 'Singapore' },
  { code: 'SX', shortName: 'Sint Maarten (Dutch part)' },
  { code: 'SK', shortName: 'Slovakia' },
  { code: 'SI', shortName: 'Slovenia' },
  { code: 'SB', shortName: 'Solomon Islands' },
  { code: 'SO', shortName: 'Somalia' },
  { code: 'ZA', shortName: 'South Africa' },
  { code: 'GS', shortName: 'South Georgia and the South Sandwich Islands' },
  { code: 'SS', shortName: 'South Sudan' },
  { code: 'ES', shortName: 'Spain' },
  { code: 'LK', shortName: 'Sri Lanka' },
  { code: 'SD', shortName: 'Sudan' },
  { code: 'SR', shortName: 'Suriname' },
  { code: 'SJ', shortName: 'Svalbard and Jan Mayen' },
  { code: 'SZ', shortName: 'Eswatini' },
  { code: 'SE', shortName: 'Sweden' },
  { code: 'CH', shortName: 'Switzerland' },
  { code: 'SY', shortName: 'Syrian Arab Republic' },
  { code: 'TW', shortName: 'Taiwan, Province of China' },
  { code: 'TJ', shortName: 'Tajikistan' },
  { code: 'TZ', shortName: 'Tanzania, United Republic of' },
  { code: 'TH', shortName: 'Thailand' },
  { code: 'TL', shortName: 'Timor-Leste' },
  { code: 'TG', shortName: 'Togo' },
  { code: 'TK', shortName: 'Tokelau' },
  { code: 'TO', shortName: 'Tonga' },
  { code: 'TT', shortName: 'Trinidad and Tobago' },
  { code: 'TN', shortName: 'Tunisia' },
  { code: 'TR', shortName: 'Türkiye' },
  { code: 'TM', shortName: 'Turkmenistan' },
  { code: 'TC', shortName: 'Turks and Caicos Islands' },
  { code: 'TV', shortName: 'Tuvalu' },
  { code: 'UG', shortName: 'Uganda' },
  { code: 'UA', shortName: 'Ukraine' },
  { code: 'AE', shortName: 'United Arab Emirates' },
  {
    code: 'GB',
    shortName: 'United Kingdom of Great Britain and Nothern Islands',
  },
  { code: 'US', shortName: 'United States of America' },
  { code: 'UM', shortName: 'United States Minor Outlying Islands' },
  { code: 'UY', shortName: 'Uruguay' },
  { code: 'UZ', shortName: 'Uzbekistan' },
  { code: 'VU', shortName: 'Vanuatu' },
  { code: 'VE', shortName: 'Venezuela, Bolivarian Republic of' },
  { code: 'VN', shortName: 'Viet Nam' },
  { code: 'VG', shortName: 'Virgin Islands, British' },
  { code: 'VI', shortName: 'Virgin Islands, U.S.' },
  { code: 'WF', shortName: 'Wallis and Futuna' },
  { code: 'EH', shortName: 'Western Sahara' },
  { code: 'YE', shortName: 'Yemen' },
  { code: 'ZM', shortName: 'Zambia' },
] as const;

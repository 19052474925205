import {
  TApmMethods,
  TCompletionIndicator,
  TConfirmPaymentParams,
  TCreateApmPaymentParams,
  TCreateCardPaymentParams,
} from '@payler/payment-page-api-gate';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../context/config-context';
import { usePaymentPatchSessionState, usePaymentSessionState } from './session';
import { useCallback, useMemo } from 'react';
import { useDeleteSavedCardMutate } from './card';
import { useBrowser } from '@payler/payment-page-ui-shared';
import { useInvalidatePayoutQuery } from '../payout';

export type TConfirmPaymentFunc = (
  attemptId: string,
  transactionID: string,
  compInd: TCompletionIndicator,
  onSuccess?: VoidFunction,
) => void;

const useCreateCardPaymentMutate = () => {
  const api = useApi();

  return useMutation({
    mutationFn: async (data: TCreateCardPaymentParams) => {
      return await api.createCardPayment(data);
    },
  });
};

const useCreateApmPaymentMutate = () => {
  const api = useApi();

  return useMutation({
    mutationFn: async (data: TCreateApmPaymentParams) => {
      return await api.createApmPayment(data);
    },
  });
};

const useConfirmPaymentMutate = () => {
  const api = useApi();
  const { session } = usePaymentSessionState();

  return useMutation({
    mutationFn: async (params: TConfirmPaymentParams) => {
      return await api.confirmPayment(session?.id, params);
    },
  });
};

const useManualConfirmApmPaymentMutation = () => {
  const api = useApi();
  const { session } = usePaymentSessionState();
  return useMutation({
    mutationFn: async () => {
      return await api.manualConfirmApmPayment(session?.id);
    },
  });
};

const useManualRejectPaymentMutation = () => {
  const api = useApi();
  const { session } = usePaymentSessionState();
  return useMutation({
    mutationFn: async () => {
      return await api.manualRejectPayment(session?.id);
    },
  });
};

export const useCreateCardPayment = () => {
  const patchSession = usePaymentPatchSessionState();
  const {
    mutate: createCardPaymentMutate,
    isPending: isCreateCardPaymentLoading,
    error: createCardPaymentError,
  } = useCreateCardPaymentMutate();
  const createCardPayment = useCallback(
    (params: TCreateCardPaymentParams, onSuccess?: VoidFunction) => {
      createCardPaymentMutate(params, {
        onSuccess: (data) => {
          patchSession(data.session);
          onSuccess?.();
        },
      });
    },
    [createCardPaymentMutate, patchSession],
  );
  return useMemo(
    () => ({
      createCardPayment,
      isCreateCardPaymentLoading,
      createCardPaymentError,
    }),
    [createCardPayment, isCreateCardPaymentLoading, createCardPaymentError],
  );
};

export const useDeleteSavedCard = () => {
  const invalidatePayoutQuery = useInvalidatePayoutQuery();
  const {
    mutate: deleteSavedCardMutate,
    isPending: isDeleteSavedCardLoading,
    error: deleteSavedCardError,
  } = useDeleteSavedCardMutate();
  const deleteCard = useCallback(
    (id: string, onSuccess?: VoidFunction) => {
      deleteSavedCardMutate(id, {
        onSuccess: async () => {
          await invalidatePayoutQuery();
          onSuccess?.();
        },
      });
    },
    [deleteSavedCardMutate, invalidatePayoutQuery],
  );
  return useMemo(
    () => ({
      deleteSavedCard: deleteCard,
      isDeleteSavedCardLoading,
      deleteSavedCardError,
    }),
    [deleteCard, deleteSavedCardError, isDeleteSavedCardLoading],
  );
};

export const useConfirmPayment = () => {
  const patchSession = usePaymentPatchSessionState();
  const {
    mutate: confirmPaymentMutate,
    error: confirmPaymentError,
    isPending: isConfirmPaymentLoading,
  } = useConfirmPaymentMutate();
  const confirmPayment = useCallback<TConfirmPaymentFunc>(
    (attemptId, transactionID, compInd, onSuccess) => {
      confirmPaymentMutate(
        {
          attemptId,
          session: {
            payment: {
              threeDSMethod: {
                attempt: {
                  '3DSServerTransID': transactionID,
                  compInd,
                },
              },
            },
          },
        },
        {
          onSuccess: (data) => {
            patchSession(data.session);
            onSuccess?.();
          },
        },
      );
    },
    [confirmPaymentMutate, patchSession],
  );
  return useMemo(
    () => ({
      confirmPayment,
      isConfirmPaymentLoading,
      confirmPaymentError,
    }),
    [confirmPayment, confirmPaymentError, isConfirmPaymentLoading],
  );
};

export const useCreateApmPayment = () => {
  const { session } = usePaymentSessionState();
  const browser = useBrowser();
  const patchSession = usePaymentPatchSessionState();
  const {
    mutate: createApmPaymentMutate,
    isPending: isCreateApmPaymentLoading,
    error: createApmPaymentError,
  } = useCreateApmPaymentMutate();
  const createApmPayment = useCallback(
    (
      payType: TApmMethods,
      options?: {
        additionalParams?: Record<string, unknown>;
        onSuccess?: VoidFunction;
      },
    ) => {
      if (!session?.id) {
        throw new Error('Not found session id');
      }

      createApmPaymentMutate(
        {
          session: {
            id: session?.id,
            payment: {
              method: payType,
              ...options?.additionalParams,
            },
            browser,
          },
        },
        {
          onSuccess: async (data) => {
            patchSession(data.session);
            options?.onSuccess?.();
          },
        },
      );
    },
    [browser, createApmPaymentMutate, patchSession, session?.id],
  );

  return useMemo(
    () => ({
      createApmPayment,
      isCreateApmPaymentLoading,
      createApmPaymentError,
    }),
    [createApmPayment, createApmPaymentError, isCreateApmPaymentLoading],
  );
};

export const useManualConfirmApmPayment = () => {
  const patchSession = usePaymentPatchSessionState();

  const {
    mutate,
    error: manualConfirmApmPaymentError,
    isPending: isManualConfirmApmPaymentLoading,
  } = useManualConfirmApmPaymentMutation();
  const manualConfirmApmPayment = useCallback(() => {
    mutate(undefined, {
      onSuccess: (data) => {
        patchSession(data.session);
      },
    });
  }, [mutate, patchSession]);

  return useMemo(
    () => ({
      manualConfirmApmPayment: manualConfirmApmPayment,
      manualConfirmApmPaymentError,
      isManualConfirmApmPaymentLoading,
    }),
    [
      isManualConfirmApmPaymentLoading,
      manualConfirmApmPayment,
      manualConfirmApmPaymentError,
    ],
  );
};

export const useManualRejectPayment = () => {
  const patchSession = usePaymentPatchSessionState();

  const {
    mutate,
    error: manualRejectPaymentError,
    isPending: isManualRejectPaymentLoading,
  } = useManualRejectPaymentMutation();

  const manualRejectPayment = useCallback(() => {
    mutate(undefined, {
      onSuccess: (data) => {
        patchSession(data.session);
      },
    });
  }, [mutate, patchSession]);

  return useMemo(
    () => ({
      manualRejectPayment: manualRejectPayment,
      manualRejectPaymentError,
      isManualRejectPaymentLoading,
    }),
    [
      isManualRejectPaymentLoading,
      manualRejectPayment,
      manualRejectPaymentError,
    ],
  );
};

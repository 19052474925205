import { Box, Center, Link, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { Link as RouterDomLink } from 'react-router-dom';
import { FC, ReactNode } from 'react';

export const FullScreenError: FC<{
  title: ReactNode;
  errorTitle: ReactNode;
  reloadPageText: string;
  errorMessage?: ReactNode;
  footer?: ReactNode;
}> = ({ title, errorTitle, footer, errorMessage, reloadPageText }) => {
  return (
    <Center flexDirection="column" w="100dvw" h="100dvh">
      <Box mb={5}>
        <Text
          textStyle={TextStyles.code}
          fontWeight="500"
          fontSize="104px"
          color="primary.100"
          lineHeight="104px"
          data-testid="error-code-title"
        >
          {title}
        </Text>
      </Box>
      <Text
        as="h2"
        textStyle={TextStyles.h2}
        fontSize="40px"
        fontWeight="500"
        lineHeight="48px"
        textAlign="center"
        data-testid="error-title"
      >
        {errorTitle}
      </Text>
      {errorMessage && (
        <Box mt={1}>
          <Text
            textStyle={TextStyles.BodyText16Regular}
            color="primary.400"
            fontSize="20px"
            lineHeight="28px"
            textAlign="center"
            data-testid="error-message"
          >
            {errorMessage}
          </Text>
        </Box>
      )}
      <Box mt={5}>
        <Link
          to="#"
          as={RouterDomLink}
          onClick={() => window.location.reload()}
          colorScheme="primary"
          data-testid="reload-page-link"
        >
          {reloadPageText}
        </Link>
      </Box>
      {footer && (
        <Box data-testid="error-footer" mt={5}>
          {footer}
        </Box>
      )}
    </Center>
  );
};

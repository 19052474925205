import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useInvalidatePayoutQuery,
  usePayout,
  usePayoutMethods,
  usePayoutScreenId,
} from '../../hooks/payout';
import { TPayoutMethod, TPayoutScreenId } from '@payler/payment-page-api-gate';
import { usePayoutRedirect } from '../../hooks/use-redirect';
import { useCreateGlobalTimerRef } from '@payler/payment-page-ui-shared';
import createLogger from 'debug';
import { useFormVariantContext } from '../form-variant-context';

const log = createLogger('PayoutPageContextProvider');

type TPaysterFlowScreen = 'PaysterConfirmTransferScreen';

export type TScreen =
  | TPayoutScreenId
  | TPaysterFlowScreen
  | 'CardScreen'
  | 'PayIdScreen'
  | 'InteractCashoutScreen'
  | 'InteractTransferScreen'
  | 'PayfixScreen'
  | 'CommunityBankScreen'
  | 'SepaInstantScreen';

type TPayoutPageContext = {
  screen: TScreen;
  setScreen: (screen: TScreen) => void;
  isOnlyMethod: boolean;
};

const PayoutPageContext = createContext<TPayoutPageContext>(
  {} as TPayoutPageContext,
);

export const PayoutPageContextProvider: FCC = ({ children }) => {
  const { dataUpdatedAt } = usePayout();
  const screenId = usePayoutScreenId();
  const [screen, setScreen] = useState<TScreen>('payoutMethodSelection');
  const methods = usePayoutMethods();
  const isOnlyMethod = Object.keys(methods).length === 1;

  useEffect(() => {
    if (screenId) {
      log('set screen %s', screenId);
      setScreen(screenId);
    }
  }, [screenId, dataUpdatedAt]);

  usePayoutPending();
  usePayoutMethodSelection(setScreen);

  const ctx = useMemo<TPayoutPageContext>(
    () => ({
      screen,
      setScreen: (screen: TScreen) => {
        log('handle change screen %s', screen);
        setScreen(screen);
      },
      isOnlyMethod,
    }),
    [screen, isOnlyMethod],
  );

  return (
    <PayoutPageContext.Provider value={ctx}>
      {children}
    </PayoutPageContext.Provider>
  );
};

/**
 * Обработка состояний выплаты
 * @link - https://payler.atlassian.net/wiki/spaces/PAYL/pages/802455553/Payout+Page+SPA
 * */
// region payout states
/**
 * Обработка состояния `PayoutPending`
 * */
const usePayoutPending = () => {
  const screenId = usePayoutScreenId();
  const intervalTimerRef = useCreateGlobalTimerRef('payoutRefetchInterval');
  const timeoutTimerRef = useCreateGlobalTimerRef('payoutRedirectTimeout');
  const redirect = usePayoutRedirect();
  const invalidateQuery = useInvalidatePayoutQuery();

  const stopTimers = useCallback(() => {
    if (intervalTimerRef.current) {
      clearInterval(intervalTimerRef.current);
      intervalTimerRef.current = null;
    }

    if (timeoutTimerRef.current) {
      clearTimeout(timeoutTimerRef.current);
      timeoutTimerRef.current = null;
    }
  }, [intervalTimerRef, timeoutTimerRef]);

  useEffect(() => {
    return () => {
      stopTimers();
    };
  }, [intervalTimerRef, stopTimers, timeoutTimerRef]);

  useEffect(() => {
    if (screenId !== 'payoutPending' && screenId !== 'payoutPaysterPending') {
      stopTimers();

      return;
    }

    if (!intervalTimerRef.current) {
      intervalTimerRef.current = setInterval(invalidateQuery, 2_000);
    }

    if (!timeoutTimerRef.current) {
      timeoutTimerRef.current = setTimeout(redirect, 30_000);
    }
  }, [
    redirect,
    screenId,
    intervalTimerRef,
    timeoutTimerRef,
    invalidateQuery,
    stopTimers,
  ]);
};

/**
 * Обработка состояния `PayoutMethodSelection`
 * Если добавляемый метод должен автоматически открываться, в случае если он единственный списке,
 * то нужно его поддержать в этом хуке
 * */
const usePayoutMethodSelection = (
  setScreen: Dispatch<SetStateAction<TScreen>>,
) => {
  const screenId = usePayoutScreenId();
  const methods = usePayoutMethods();
  const { setFormVariant } = useFormVariantContext();

  useEffect(() => {
    if (screenId !== 'payoutMethodSelection') {
      return;
    }

    const methodsKeys = Object.keys(methods) as TPayoutMethod[];

    if (methodsKeys.length !== 1) {
      return;
    }

    switch (methodsKeys[0]) {
      case 'payid':
        setScreen('PayIdScreen');
        break;
      case 'cards':
        setScreen('CardScreen');
        break;
      case 'interaccashout':
        setScreen('InteractCashoutScreen');
        break;
      case 'interactransfer':
        setScreen('InteractTransferScreen');
        break;
      case 'payfix':
        setScreen('PayfixScreen');
        break;
      case 'communitybank':
        setScreen('CommunityBankScreen');
        break;
      case 'payster':
        setFormVariant('payster');
        break;
      case 'sepainstanttfeur':
        setScreen('SepaInstantScreen');
        break;
    }
  }, [methods, screenId, setFormVariant, setScreen]);
};

// endregion

export const usePayoutPageContext = () => useContext(PayoutPageContext);
